<template>
  <div class="container">
    <!-- 页头 -->
    <content-title :nav="nav"></content-title>
    <!-- 表头 -->
    <div class="sec-title">礼物列表</div>

    <div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column align="center" prop="activity_id" label="活动id">
        </el-table-column>
        <el-table-column align="center" prop="id" label="id"> </el-table-column>
        <el-table-column align="center" prop="pic" label="图片">
          <template slot-scope="scope">
            <img
              :src="scope.row.pic"
              alt=""
              style="width: 100px; height: 100px"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="gift_name" label="礼物名称">
        </el-table-column>
        <el-table-column align="center" prop="amount" label="金额">
        </el-table-column>
        <el-table-column align="center" prop="discount_amout" label="折扣价">
        </el-table-column>
        <el-table-column align="center" prop="status" label="状态">
          <template slot-scope="scope">
            <el-button
              @click="opendis(scope.row)"
              type="success"
              >修改</el-button
            >
            <el-button
              v-if="scope.row.status != 3"
              @click="handleClick(scope.row, 3)"
              type="success"
              >上架</el-button
            >
            <el-button v-else @click="handleClick(scope.row, 4)" type="danger"
              >下架</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <paging-fy
      @currentPageChange="pageChange"
      :currentPage="currenPage"
      :total="total"
    ></paging-fy>
    <!-- <el-pagination :total="total" :current-page="currenPage" @page-change='pageChange'></el-pagination> -->
    <el-dialog title="添加礼物" :visible.sync="dialogFormVisibleliwu" width="600">
      <el-form :model="liwudata">
        <el-form-item label="礼物名称" :label-width="formLabelWidth" style="margin-bottom: 30px">
          <el-input v-model="liwudata.gift_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="金额" :label-width="formLabelWidth" style="margin-bottom: 30px">
          <el-input v-model="liwudata.amount" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="数量/概率" :label-width="formLabelWidth" style="margin-bottom: 30px">
          <el-input v-model="liwudata.discount_amout" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="说明" :label-width="formLabelWidth" style="margin-bottom: 30px">
          <el-input v-model="liwudata.introduction" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="类型" :label-width="formLabelWidth" style="margin-bottom: 30px">
          <el-input v-model="liwudata.type" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="排序" :label-width="formLabelWidth" style="margin-bottom: 30px">
          <el-input v-model="liwudata.point" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="礼物图片" :label-width="formLabelWidth" style="margin-bottom: 30px">
          <avatar-uploader @getImgUrl="Uploadcourintroductioncover444" :clear="if_clear" :url='liwudata.pic'>
          </avatar-uploader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleliwu = false">取 消</el-button>
        <el-button type="primary" @click="tianjialiwu">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: {
        firstNav: "活动管理",
        secondNav: "礼物管理",
      },
      tableData: [],
      total: null,
      is_loading: true,
      currenPage: 1,
      liwudata:{},
      dialogFormVisibleliwu:false,
      if_clear:true,
      gift_pic:""
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    opendis(row){
      this.dialogFormVisibleliwu = true
      this.liwudata = row
    },
    getList() {
      this.is_loading = true;
      this.$axios
        .get("/user/userGift/queryManagerListPage", {
          params: {
            pageSize: 10,
            currentPage: this.currentPage,
          },
        })
        .then((res) => {
          this.tableData = res.data.data.rows;
          this.total = res.data.data.total;
        });
    },
    pageChange(v) {
      this.currentPage = v;
      this.getList();
    },
    handleClick(row, num) {
      let url = "/user/userGift/update";
      let data = {
        id: row.id,
        categories: "体育运动",
        status: num,
      };
      this.$axios.post(url, data).then((res) => {
        this.getList();
      });
    },
    tianjialiwu(){
      let url = "/user/userGift/update";
      let data = this.liwudata
      this.$axios.post(url, data).then((res) => {
        this.dialogFormVisibleliwu=false
        this.getList();
      });
    },
    Uploadcourintroductioncover444(file) {
      this.liwudata.pic = file;
    },


  },
};
</script>
